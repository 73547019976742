import Vue from 'vue'
import axios from "axios";//原生的axios
//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
let serveId = localStorage.getItem("serveId");
//创建一个单例
const http= axios.create({
  // baseURL:'http://192.168.110.35:8081',
  baseURL:'https://npcs.31cq.com',
  timeout:10000,//响应时间
  headers: {
    "server": 1
  }
  
  // headers:{"Content-Type":"application/json;charset=utf-8"},
})
const CancelToken = axios.CancelToken

 
//拦截器  -请求拦截
http.interceptors.request.use(config=>{
  //部分接口需要token
  // let token=localStorage.getItem('token');
  // if(token){
  //   // config.headers.token=token;
  //   config.headers.Authorization = 'Bearer ' + token
  // }
  // 强行中断请求要用到的，记录请求信息
  config['cancelToken'] = new CancelToken(function executor(cancel) {
    Vue.$httpRequestList.push(cancel) //存储cancle
  })
    return config;
},err=>{
  return Promise.reject(err)
})
 
//拦截器  -响应拦截
http.interceptors.response.use(res=>{
    // serveId = res.headers['service-id']
    if(res.data.code == 200){
      if(res.headers['service-id'] != undefined) {
          localStorage.setItem("serveId",res.headers['service-id']);
      }
    // console.log(res.data)
    return Promise.resolve(res.data,res)
    //这里读者们可以根据服务器返回的数据去自行修改
  }else{
    return Promise.reject(res.data)
  }
},err=>{
    if(err.message === 'interrupt') {
        console.log('请求中断');
        return new Promise(() => {});
      }
  return Promise.reject(err)
});
 
//整体导出
export default http;
 